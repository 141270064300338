export default {
  name: 'setting-course-type-item',
  props: {
    item: {},
    locale: {}
  },
  data () {
    return {
      payload: {
        name: '',
        description: ''
      }
    }
  },
  watch: {
    list () {
      this.serData()
    }
  },
  created () {
    this.serData()
  },
  methods: {
    updateField () {
      const obj = {}
      obj[`${this.locale}[name]`] = this.payload.name
      obj[`${this.locale}[description]`] = this.payload.description
      this.$emit('update-data', obj)
    },
    serData () {
      if (!this.item) return ''
      const item = this.item.translations.data.find(e => e.locale === this.locale)
      Object.assign(this.payload, item)
    }
  }
}
