import { mapActions, mapGetters, mapMutations } from 'vuex'
import item from '../../components/course-type-item/index.vue'
import validation from '@/mixins/validation'

export default {
  name: 'setting-course-types',
  mixin: [validation],
  data () {
    return {
      page: 1,
      showDialog: false,
      valid: '',
      selectedItem: null,
      currentLocale: 'uk',
      removedItem: null,
      showRemoveDialog: false,
      payload: {
        title: '',
        active: false,
        sort: ''
      }
    }
  },
  components: {
    item
  },
  computed: {
    ...mapGetters({
      list: 'settingCourseSkills/list',
      limit: 'settingCourseSkills/limit',
      listLength: 'settingCourseSkills/listLength',
      isLoading: 'settingCourseSkills/isLoading',
      isListLoading: 'settingCourseSkills/isListLoading',
      locales: 'setting/basicLocales'
    }),
    headers () {
      return [
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  created () {
    this.fetchList()
    this.fetchBasicLocales().then(() => {
      this.currentLocale = this.locales[0].locale
    })
  },
  methods: {
    ...mapActions({
      fetchList: 'settingCourseSkills/GET_LIST',
      fetchBasicLocales: 'setting/GET_BASIC_LOCALES',
      create: 'settingCourseSkills/CREATE',
      remove: 'settingCourseSkills/DELETE',
      update: 'settingCourseSkills/UPDATE'
    }),
    ...mapMutations({
      changeSkip: 'settingCourseSkills/CHANGE_SKIP'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialog (item = null, status = false) {
      this.showDialog = status
      this.selectedItem = item
      if (item) {
        this.setData(item)
      } else {
        this.payload = {}
        this.payload.sort = ''
        this.payload.title = ''
      }
    },
    setData (item) {
      Object.assign(this.payload, item)
      const list = item.translations.data
      list.forEach(e => {
        this.payload[`${e.locale}[name]`] = e.name
        this.payload[`${e.locale}[description]`] = e.description
      })
    },
    changeDialogDelete (status = false, item = null) {
      this.removedItem = item
      this.showRemoveDialog = status
    },
    removeListItem () {
      this.remove(this.removedItem).then(() => {
        this.changeDialogDelete()
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    updateData (data) {
      Object.assign(this.payload, data)
    },
    createRequestPayload () {
      const formData = new FormData()
      for (const i in this.payload) {
        formData.append(i, this.payload[i])
      }
      formData.append('active', this.payload.active ? 1 : 0)
      this.payload.hasOwnProperty('id') ? formData.append('_method', 'patch') : ''
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.payload.hasOwnProperty('id')) {
          this.update(this.createRequestPayload()).then(() => {
            this.changeDialog()
            this.$toasted.success(this.$t('success_updated'))
          })
        } else {
          this.create(this.createRequestPayload()).then(response => {
            this.selectedItem = response
            this.changeDialog()
            this.$toasted.success(this.$t('success_create'))
          })
        }
      }
    }
  }
}
